import { MenuItem, Select, Typography } from '@mui/material';
import { type Geschaeftsbereich, type VivaStatus } from 'dtos';
import { type ReactElement, type useState } from 'react';
import { v4 } from 'uuid';

type TextOrSelectProps<T> = {
  readonly isEdit: boolean;
  readonly valueState: ReturnType<typeof useState<T | undefined>>;
  readonly selections: Array<{ value: VivaStatus | Geschaeftsbereich | undefined; name: string }>;
};

export const TextOrSelect = <T,>(props: TextOrSelectProps<T>): ReactElement<TextOrSelectProps<T>> => {
  const [value, setValue] = props.valueState;

  if (!props.isEdit) {
    return <Typography component="span">{props.selections.find((sel) => sel.value === value)?.name ?? '-'}</Typography>;
  }

  return (
    <Select
      value={value}
      variant="standard"
      size="small"
      onChange={(event) => {
        setValue(event.target.value as T);
      }}
    >
      {props.selections.map((sel) => (
        <MenuItem key={v4()} value={sel.value}>
          {sel.name}
        </MenuItem>
      ))}
    </Select>
  );
};
